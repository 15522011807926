import * as React from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Box } from '@mui/material';

import './SchoolTotalsChart.css';

export interface SchoolTotalsChartProps {
    schoolTotalsData: {
        schoolName: string,
        count: number
    }[]
}

export function SchoolTotalsChart(props: SchoolTotalsChartProps) {
    const { schoolTotalsData } = props;
    
    const minHeight = 350;
    const height = schoolTotalsData.length * 50 < minHeight ? minHeight : schoolTotalsData.length * 50;
    return (
        <Box className='school-totals-chart-box'>
            <ResponsiveContainer width={'100%'} height={height}>
                <BarChart
                    width={450}
                    data={schoolTotalsData}
                    layout={'vertical'}
                    key={Math.random()} // Used to make the animation work even with re-render
                >
                    <XAxis type={'number'} />
                    <YAxis dataKey='schoolName' type={'category'} interval={0}  width={125}/>
                    <Bar 
                        dataKey='count' 
                        fill='#7B2BBE' 
                        label={{ fill: 'white', fontSize: 20 }}
                        barSize={30}
                    />
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
}